import { io } from "socket.io-client";
// import logger from "../../../src/helpers/winston-helper";

const BACKEND_URI = process.env.REACT_APP_BACKEND_URL || "";

export const socket = io(BACKEND_URI, {
  autoConnect: false,
  withCredentials: true,
});

export function connectSocket() {
  try {
    socket.connect();
  } catch (error) {
    // logger('server-error').error(error);
    console.log(error);
  }
}

export function disconnectSocket() {
  try {
    socket.disconnect();
  } catch (error) {
    // console.log(error);    
  }
}
