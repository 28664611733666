import axios from "axios";
import CryptoJS from "crypto-js";

const BACKEND_URI = process.env.REACT_APP_BACKEND_URL || "";
const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY || "";

export const api = (token) => {
  if (typeof token === "string" && token.split(".").length === 3) {
    const encryptedToken = CryptoJS.AES.encrypt(
      JSON.stringify(token),
      secretKey
    ).toString();

    return axios.create({
      baseURL: `${BACKEND_URI}/api/`,
      headers: {
        Authorization: encryptedToken,
      },
    });
  }
  else
    return axios.create({
      baseURL: `${BACKEND_URI}/api/`,
    });
};

export const handleResponse = (res) => {
  const { success, data } = res;

  if (success === false) {
    const errorMsg = data.message ? data.message : "Something Went Wrong";
    throw new Error(errorMsg);
  }

  return data;
};

export const handleError = (error) => {
  try {
    const { response: { status, statusText } } = error;
    if (status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userData")
      if (window.location.pathname !== "/") {
        window.location = "/";
      }
    }

    throw new Error(statusText);
  } catch (error) {
    throw new Error("Something went Wrong");
  }
};
