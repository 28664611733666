import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { useLocation } from "react-router-dom";
import { Button, ListItemText, TextField } from '@mui/material';
import { getButtonData, getChatMessage, getSuggestions } from '../../services/chat.service';
import { connectSocket, disconnectSocket } from '../../config/socket.config';
import { listenAdminMessage, updateUserSocketId } from '../../services/socket.service';


const BACKEND_URI = process.env.REACT_APP_BACKEND_URL || "";
export default function ChatBox({ handleCallback, testData = {} }) {
  const search = useLocation().search;

  const userId = new URLSearchParams(search).get("userId");
  const shopId = new URLSearchParams(search).get("shopId");
  const campaignId = new URLSearchParams(search).get("campaign_id");
  const product = {
    id: new URLSearchParams(search).get("id"),
    name: new URLSearchParams(search).get("name"),
    price: new URLSearchParams(search).get("price") / 100,
    variantId: new URLSearchParams(search).get("variantId"),
  };


  const [showChatBox, setShowChatBox] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [status, setStatus] = useState("progress");
  const [discount, setDiscount] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [themeColor, setThemeColor] = useState("#e52900");
  const [companyLogo, setCompanyLogo] = useState("");
  const [showLastDealButton, setShowLastDealButton] = useState(false)
  const [showDealButton, setShowDealButton] = useState(true);
  const [userQuery, setUserQuery] = useState("");
  const messagesEndRef = useRef(null);
  const queryInput = useRef(null);

  //-----------------------------------------------> handlers
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setShowChatBox(open);
    handleCallback(false);
  };

  const scrollToBottom = () => {
    const section = document.querySelector('#bottom-reply');
    section && section.scrollIntoView();
    // if (messagesEndRef.current) {
    //   messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
  };

  const setAIReponse = async (newQuery = "", isDealAccepted = false, checkEmail = true) => {
    try {
      setShowDealButton(false);
      setLoading(true);
      const datas = {
        productId: product.id,
        shopId: shopId,
        userId,
        variantId: product.variantId,
        price: product.price,
        productName: product.name,
        isDealAccepted,
        checkEmail
      };

      if (userQuery || newQuery) {
        datas.query = userQuery || newQuery;
      }

      if (campaignId) {
        datas.campaignId = campaignId;
      }

      const query = new URLSearchParams({ ...datas, ...testData }).toString();
      const { data: {
        answers,
        discount,
        status,
        event,
        settings = [],
        quantity,
      } } = await getChatMessage(shopId, query);
      setTimeout(() => {
        setConversations((conversations) => [
          ...conversations,
          ...answers,
        ]);
        settings.forEach(element => {
          if (element?.type === "color") {
            setThemeColor(element?.value);
          }
        });
        setDiscount(discount);
        setStatus(status);
        setQuantity(quantity);
        setLoading(false);
        setShowDealButton(event === "confirm_deal");
        setShowLastDealButton(event === "ask_email");

      }, event === 'start' ? 100 : 2000)
      // const suggestionQuery = new URLSearchParams({ event }).toString();
      // const { data: suggestionData } = await getSuggestions(shopId, suggestionQuery);
      // setSuggestions(suggestionData?.sort((a, b) => a?.content?.length - b?.content?.length));

    } catch (error) {
      console.log(error);
    }
  }


  const handleClick = async () => {
    try {
      setUserQuery(userQuery => {
        if (userQuery) {
          const userConv = [
            ...conversations,
            {
              content: userQuery,
              userType: "user"
            }
          ];

          setConversations(userConv);
          setSuggestions([]);

          setAIReponse(userQuery);
        }

        return "";
      })
    } catch (error) {
      setLoading(false);
    }
  }

  const handleDealClick = () => {
    setAIReponse("yes", true);
  }

  const handleLastAccept = () => {
    setAIReponse("yes", true, false);
  }
  const handleRejectDeal = () => {
    setAIReponse("no");
  }

  const sendMessageToParent = (event) => {
    window.parent.postMessage(event, "*");
  }

  const handleClose = () => {
    toggleDrawer(false);
    if (status === "end" && !discount.code) {
      sendMessageToParent("resetChat");
    }
    sendMessageToParent("closeChat");
  }

  const handleResetChat = () => {
    toggleDrawer(false);
    sendMessageToParent("resetChat");
  }

  const handleAddToCart = () => {
    sendMessageToParent({
      event: "add_to_cart",
      variantId: product.variantId,
      quantity,
      discountCode: discount.code,
    })
  }

  const handleQueryInput = (e) => {
    const { value } = e.target;

    setUserQuery(value);
  }

  const handleAdminReponse = (params) => {
    const {
      id,
      query,
      event,
      value,
      variantId,
      userId: adminUserId,
    } = params;
    if (product.variantId === variantId && adminUserId === userId) {
      switch (event) {
        case "close-chat":
          setStatus("end");
          return;
        case "given-discount":
          setDiscount(value);
          setStatus("end");
          break;
        default:
      }

      setSuggestions([]);
      setConversations((data) => {
        return [
          ...data,
          {
            id,
            content: query,
          }
        ]
      });
    }
  }

  const getChatSettingData = async () => {
    try {
      const data = await getButtonData(shopId, {
        shopId: shopId,
        productId: product.id
      });
      setCompanyLogo(data?.data?.companyLogo || "");
    } catch (error) {
      console.log(error);
    }
  }
  //-----------------------------------------------> useEffects
  useEffect(() => {
    if (queryInput?.current) {
      queryInput?.current?.focus();
    }
    scrollToBottom();

    window.addEventListener('resize', scrollToBottom);
    return () => {
      window.removeEventListener('resize', scrollToBottom);
    };
  }, [conversations]);

  useEffect(() => {
    setAIReponse();
    connectSocket();
    getChatSettingData();

    const datas = {
      productId: product.id,
      shopId: shopId,
      userId,
      variantId: product.variantId,
      price: product.price,
      productName: product.name
    };
    updateUserSocketId(datas);

    listenAdminMessage(handleAdminReponse);

    return () => {
      disconnectSocket();
    }
  }, []);

  return (
    <Drawer
      anchor={"right"}
      open={showChatBox}
      className='drawerBox'
    >
      <>
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#fff",
          }}
          role="presentation"
        >
          <List>
            <ListItem disablePadding sx={{ display: "flex" }}>
              <Button>
                {companyLogo ? <img
                  src={
                    // companyLogo
                    // ?
                    `${BACKEND_URI}/images/${companyLogo}`
                    // : "/logo.png"
                  }
                  alt="crazy"
                  width={40}
                  height={40}
                  style={{ objectFit: "contain" }}
                /> :
                  <img
                    src={
                      "https://crazy-converter.s3.us-east-2.amazonaws.com/logo.png"}
                    alt="crazy"
                    width={40}
                    height={40}
                    style={{ objectFit: "contain" }}
                  />}
              </Button>

              <ListItemText className='title-text'>{product.name ?? testData?.productName}</ListItemText>

              <Button className='close-button' onClick={handleClose}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.0938 0.907227L0.90625 13.0947M0.90625 0.907227L13.0938 13.0947" stroke="#4D2501" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Button>
            </ListItem>
            <Divider />
          </List>
        </Box>

        <Box sx={{ flex: 1 }}>
        </Box>

        <List sx={{ pb: 6 * suggestions?.length }} >
          {
            conversations.map((data, i) => (
              <div
                key={i}
                className='chatbox-row'
                ref={messagesEndRef}
              >
                <span
                  className={`${data.userType === "user" ? "userConv animate__fadeInUp" : "aiConv animate__fadeInUp"
                    } conversations`}
                  style={{ backgroundColor: data.userType === "user" ? themeColor + "66" : "rgb(245, 248, 250)", padding: "8px 15px" }}
                >
                  {data.content}
                </span>
                {
                  i === conversations.length - 1
                  && data?.situation === "confirm_deal"
                  && showDealButton &&
                  <div className='dealButton'>
                    <Button
                      style={{
                        background: "#e52900",
                        marginBlock: 5,
                        borderRadius: 40,
                        paddingInline: 25,
                        boxShadow: "none",
                      }}
                      variant='contained'
                      onClick={handleDealClick}
                    >
                      Accept
                    </Button>
                    <Button
                      style={{
                        background: "#fff",
                        marginBlock: 5,
                        borderRadius: 40,
                        paddingInline: 25,
                        border: "1px solid #e52900",
                        color: "#e52900",
                        boxShadow: "none",
                      }}
                      variant='contained'
                      onClick={handleRejectDeal}
                    >
                      Decline
                    </Button>
                  </div>
                }
                {
                  i === conversations.length - 1 &&
                  showLastDealButton && (
                    <>
                      <div style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "10px",
                      }}>
                        <Button style={{
                          display: "inline-block",
                          padding: "10px",
                          border: "1px solid #333333",
                          borderRadius: "5px",
                          lineHeight: "100%",
                          color: "#333333",
                          textTransform: "capitalize",
                        }}
                          onClick={handleLastAccept}>
                          Accept Last Offer
                        </Button>
                      </div>
                    </>
                  )
                }
              </div>
            ))
          }

          {
            isLoading &&
            <div className="chatbox-row">
              <div className="aiConv loader" style={{ backgroundColor: themeColor + "16" }}>
                {/* <div className="dot-pulse"></div> */}
                <div className="chatbox-loader"></div>
              </div>
            </div>
          }

          {
            status === "end" && !discount.code &&
            <div className="dealButton" onClick={handleResetChat}>
              <Button
                color='error'
                style={{
                  width: "calc(100% - 30px)",
                  color: "#e52900",
                  border: "1px solid #e52900",
                  borderRadius: 100,
                }}
              >
                Deal Closed
              </Button>
            </div>
          }

          {
            discount.code &&
            <div className="accepted_offer" onClick={handleAddToCart}>
              <div className='discount-hr-image'><img src="/images/offer.gif" alt="accepted" /></div>
              <p>You got a <span className='discount-span-text'>{discount.value}%</span> discount!</p>
              <Button
                color='success' variant='contained'
                style={{
                  width: "calc(100% - 30px)",
                  color: "#e52900",
                  border: "1px solid #e52900",
                  background: "white",
                  boxShadow: "none",
                  borderRadius: 100,
                  fontWeight: 600,
                  paddingBlock: 10,
                }}
              >
                Add To Cart
              </Button>
            </div>
          }

          <div id="bottom-reply"></div>
        </List>
        {status !== "end"
          && <Box
            sx={{
              display: 'flex',
              width: "100%",
              maxWidth: 400
            }}
            className='inputSection'
          >
            <TextField
              placeholder="Chat here..."
              name="query"
              inputRef={queryInput}
              className='queryInput'
              size="small"
              sx={{ flex: 1 }}
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              onChange={handleQueryInput}
              onKeyDown={(e) => {
                if (e.key === "Enter" && status !== "end" && !isLoading) {
                  handleClick();
                }
              }}
              disabled={status === "end" || isLoading}
              value={userQuery}
              autoComplete='off'
            />
            <Button
              className='send-button'
              sx={{ color: themeColor }}
              onClick={handleClick}
              disabled={status === "end" || isLoading}
            >
              <svg width="20" height="18" viewBox="0 0 20 18" fill={themeColor} xmlns="http://www.w3.org/2000/svg">
                <path d="M0.00783647 16.4855C0.00886129 17.2029 0.74259 17.6859 1.40191 17.4032L18.8565 9.91908C19.6644 9.57267 19.6644 8.42733 18.8565 8.08092L1.40191 0.596818C0.74259 0.314117 0.00886129 0.797092 0.00783647 1.51447L0.00125254 6.12322C0.000535762 6.62497 0.371743 7.04957 0.869088 7.11588L7.56579 8.00877C8.72291 8.16305 8.72291 9.83695 7.56579 9.99123L0.869087 10.8841C0.371742 10.9504 0.00053576 11.375 0.00125254 11.8768L0.00783647 16.4855Z" fill={themeColor}>
                </path>
              </svg>
            </Button>
          </Box>}
        <Box>
          <p className='company-ref'>
            Negotiate by
            <a
              href="https://crazyconvert.com"
              target='_blank'
              rel="noreferrer"
              style={{ color: themeColor }}
            >
              CrazyConvert
            </a>
          </p>
        </Box>
      </>
    </Drawer>
  );
}
