import Chatbot from './screens/Chatbot';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Chatbot />} path="/" exact={true} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
