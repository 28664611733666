import { api, handleError, handleResponse } from "../config/axios.config";

export const getChatMessage = (token, query) =>
    api(token)
        .get(`/message?${query}`)
        .then(handleResponse)
        .catch(handleError);

export const getSuggestions = (token, query) =>
    api(token)
        .get(`/suggestions?${query}`)
        .then(handleResponse)
        .catch(handleError);

export const getUserChats = (token, query) =>
    api(token)
        .get(`/chats?${query}`)
        .then(handleResponse)
        .catch(handleError);

export const getButtonData = (token, data) =>
    api(token)
    .get(`/shop/${data.shopId}/${data.productId}/button`)
    .then(handleResponse)
    .catch(handleError);