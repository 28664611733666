// import { Button } from "@mui/material";
import { ChatBox } from "../components";
import { useState } from "react";

export default function Chatbot() {
  const [showChatScreen, setShowChatScreen] = useState(true);

  const hideDrawer = () => {
    setShowChatScreen(false);
  }

  return (
    <div>
      {showChatScreen && <ChatBox handleCallback={hideDrawer} />}
      {/* <div className="chatBtn">
        <Button variant="contained" onClick={() => setShowChatScreen(true)}>chat</Button>
      </div> */}
    </div>
  )
}
